import React, { createContext, PropsWithChildren, RefObject, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalContextType = RefObject<HTMLElement>;

const PortalContext = createContext({} as PortalContextType);

/**
 * Used to set up the place where the portal content shall be rendered and a context provider for accessing the portal.
 *
 * Note that there is only ONE portal context throughout the whole app!
 */
export const usePortalHost = () => {
    const ref = useRef(null);

    /** Provides the portal ref as context to its children. */
    const PortalContextProvider = ({ children }: PropsWithChildren<{}>) => (
        <PortalContext.Provider value={ref}>{children}</PortalContext.Provider>
    );

    /** Injects our portal ref into the given element. */
    const PortalHost = ({ element }: { element: JSX.Element }) => ({ ...element, ref });

    return { PortalContextProvider, PortalHost };
};

/**
 * Provides a Portal component that renders its children into the PortalHost created by `usePortalHost`.
 *
 * Note that there is only ONE portal context throughout the whole app!
 */
export const usePortal = () => {
    const ref = useContext(PortalContext);

    const Portal = ({ children }: PropsWithChildren<{}>) => {
        return ref.current && createPortal(children, ref.current);
    };

    return { Portal };
};
