import React, { lazy } from 'react';
import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import SuspendedWithSpinner from '../components/SuspendedWithSpinner';
import AppLayout from '../layout/AppLayout';

// Lazy load pages for better performance and automatically split the bundle accordingly
const CreateDialog = lazy(() => import('../pages/CreateDialogWrapper'));

export const DEFAULT_ROUTE = '/connect';

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />}>
            <Route
                path={DEFAULT_ROUTE}
                errorElement={<ErrorBoundary />}
                element={
                    <SuspendedWithSpinner>
                        <CreateDialog />
                    </SuspendedWithSpinner>
                }
            />
        </Route>
    )
);
