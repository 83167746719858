import { accessToken } from '../configuration/tokenHandling/accessToken';
import { AccessToken } from '../configuration/tokenHandling/tokenSlice';
import { z } from 'zod';
import { version as uuidVersion } from 'uuid';
import { isNullOrEmpty } from '../components/helper';

export const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const isFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';
export const isRejected = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === 'rejected';

export type BackendErrorResponse = {
    value: {
        error: unknown;
    };
};
export const customIsRejected = (p: unknown): p is BackendErrorResponse =>
    typeof p === 'object' &&
    p != null &&
    'value' in p &&
    typeof p.value === 'object' &&
    p.value != null &&
    'error' in p.value &&
    typeof p.value.error === 'object' &&
    p.value.error != null;

export const problem = z.object({ status: z.number(), detail: z.string() });

export const problemResponse = z.object({
    status: z.number(),
    data: z.unknown(),
});

export const responseHandler = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            return;
        }
        try {
            return response.json();
        } catch (error) {
            throw new Error(`${response.status} Invalid payload: ${error}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

export const isUuidv4 = (str: string | undefined): boolean => {
    try {
        return !isNullOrEmpty(str) && uuidVersion(str!) === 4;
    } catch (e) {
        return false;
    }
};

export const getMilliSecondsFromDays = (days: number) => days * 24 * 60 * 60 * 1000;
