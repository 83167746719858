import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import DefaultRedirect from '../routes/DefaultRedirect';

import './App.css';
import { usePortalHost } from '../hooks/usePortal';
import React from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

const AppLayout = () => {
    const { PortalContextProvider, PortalHost } = usePortalHost();

    const footer = (
        <PortalHost
            element={
                <div
                    className="display-flex justify-content-between flex-row-reverse padding-20
                bg-white border border-top-only rounded-bottom hidden-empty non-printable"
                />
            }
        />
    );

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout data-testid={'app-layout'} className="printable-content">
                <div className="display-flex flex-column height-100vh">
                    <div className={'flex-1-1 padding-20 overflow-y-auto'}>
                        <PortalContextProvider>
                            <Outlet />
                        </PortalContextProvider>
                    </div>
                    {footer}
                </div>
                <DefaultRedirect />
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default AppLayout;
