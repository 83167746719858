import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './utils';
import { config } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { AssetInput } from '../pages/types';
import { z } from 'zod';
import { Device } from '../actions/types';

const vinResponse = z.object({
    vin: z.string(),
    brand: z.string(),
});

const associationsGetResponse = z
    .object({
        items: z.array(
            z.object({
                id: z.string(),
                device_id: z.string(),
                asset_id: z.string(),
            })
        ),
    })
    .transform((val) => ({
        items: val.items.map((it) => ({
            id: it.id,
            deviceId: it.device_id,
            assetId: it.asset_id,
        })),
    }));

type Association = { assetId: string; deviceId: string; id: string };

const assetByIdResponse = z
    .object({
        id: z.string(),
        name: z.string(),
        type: z.string(),
        license_plate: z.string().nullable().optional(),
        license_plate_country_code: z.string().nullable().optional(),
        identification: z.string().nullable().optional(),
        brand: z.string().nullable().optional(),
    })
    .transform((response) => ({
        id: response.id,
        name: response.name,
        type: response.type,
        licensePlate: response.license_plate,
        licensePlateCountryCode: response.license_plate_country_code,
        identification: response.identification,
        brand: response.brand,
    }));

export type ExistingAsset = z.infer<typeof assetByIdResponse>;

export const assetsApi = createApi({
    reducerPath: 'rtkq/assetsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.assetAdmin, prepareHeaders }),
    endpoints: (build) => ({
        getAssetById: build.query<ExistingAsset, { assetId: string }>({
            query: ({ assetId }) => ({
                url: `/assets/${assetId}`,
            }),
            transformResponse: (baseQueryReturnValue) => assetByIdResponse.parse(baseQueryReturnValue),
        }),
        getBrand: build.query<string | undefined, { vin: string }>({
            query: ({ vin }) => ({
                url: `/vins/${vin}`,
            }),
            transformResponse: (rawResult) => vinResponse.parse(rawResult).brand,
        }),

        createAsset: build.mutation<void, AssetInput>({
            query: (asset) => {
                return {
                    url: `/assets/${asset.id}`,
                    method: 'PUT',
                    body: asset,
                };
            },
        }),
        createDevice: build.mutation<void, Device>({
            query: (device) => {
                return {
                    url: `/devices/${device.id}`,
                    method: 'PUT',
                    body: device,
                };
            },
        }),

        createAssociation: build.mutation<Association, { assetId: string; deviceId: string }>({
            query: ({ assetId, deviceId }) => {
                const associationId = uuidv4();
                return {
                    url: `/associations/${associationId}`,
                    method: 'PUT',
                    body: {
                        id: associationId,
                        device_id: deviceId,
                        asset_id: assetId,
                    },
                };
            },
        }),

        getAssociationsForAsset: build.query<Association[], { assetId: string }>({
            query: ({ assetId }) => ({
                url: '/associations',
                params: { asset_id: assetId },
            }),
            transformResponse: (rawResult) => associationsGetResponse.parse(rawResult).items,
        }),
    }),
});

export const {
    useLazyGetBrandQuery,
    useCreateAssetMutation,
    useCreateDeviceMutation,
    useCreateAssociationMutation,
    useLazyGetAssociationsForAssetQuery,
    useLazyGetAssetByIdQuery,
} = assetsApi;
