import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import { prepareHeaders, responseHandler } from './utils';
import { z } from 'zod';

interface AppLoginCreation {
    loginId: string;
    name: string;
}

interface AppLoginCredentials {
    keyId: string;
    content: string;
}

const TagType = {
    APP_LOGINS: 'AppLogins',
} as const;

const appLoginCredentialsRest = z.object({ key_id: z.string(), content: z.string() });

const appLoginCredentialsSchema = appLoginCredentialsRest.transform((it) => ({
    keyId: it.key_id,
    content: it.content,
}));

const appLoginSchema = z.object({
    appLoginId: z.string(), // uuid
    description: z.string(), // uuid
    appName: z.string(),
    creationDate: z.string(),
    status: z.string(),
});
type AppLogin = z.infer<typeof appLoginSchema>;

const appLoginQueryRequest = z.object({
    login_id: z.string(),
    name: z.string(),
    app_id: z.string(),
    account_id: z.string(),
    status: z.string(),
    creation_date: z.string(),
});

const appLoginQueryApiResponse = z.object({ items: z.array(appLoginQueryRequest) });

const appLogins = appLoginQueryApiResponse.transform((res) =>
    res.items.map((it) => ({
        appLoginId: it.login_id,
        description: it.name,
        appName: 'RIO  4Drivers App',
        creationDate: it.creation_date,
        status: it.status,
    }))
);

const appLoginCreationRest = z.object({
    login_id: z.string(),
    name: z.string(),
});

const appLoginCreation = appLoginCreationRest.transform((res) => ({
    loginId: res.login_id,
    name: res.name,
}));

export const appLoginApi = createApi({
    reducerPath: 'appLoginApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.appLoginService, prepareHeaders }),
    tagTypes: [TagType.APP_LOGINS],
    endpoints: (build) => ({
        getAppLogins: build.query<AppLogin[], void>({
            query: () => {
                const limit = 1000;
                return {
                    url: '',
                    params: { limit },
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => appLogins.parse(rawResult),
            providesTags: [TagType.APP_LOGINS],
        }),
        createAppLogin: build.mutation<AppLoginCreation, { appId: string; appLoginName: string }>({
            query: ({ appId, appLoginName }) => {
                return {
                    url: '',
                    method: 'POST',
                    body: { app_id: appId, name: appLoginName },
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => appLoginCreation.parse(rawResult),
        }),
        deleteAppLogin: build.mutation<any, { appLoginId: string }>({
            query: ({ appLoginId }) => {
                return {
                    url: `/${appLoginId}`,
                    method: 'DELETE',
                    responseHandler,
                };
            },
            invalidatesTags: [TagType.APP_LOGINS],
        }),
        createAppLoginCredentials: build.mutation<AppLoginCredentials, { appLoginId: string }>({
            query: ({ appLoginId }) => {
                return {
                    url: `/${appLoginId}/credentials`,
                    method: 'POST',
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => appLoginCredentialsSchema.parse(rawResult),
        }),
        createAppLoginWithCredentials: build.mutation<
            AppLoginCredentials & { loginId: string },
            { appId: string; appLoginName: string }
        >({
            queryFn: async (arg, _queryApi, _extraOptions, fetchBQ) => {
                const { appId, appLoginName } = arg;
                // create app login
                const appLoginResult = await fetchBQ({
                    url: '',
                    method: 'POST',
                    responseHandler,
                    body: {
                        app_id: appId,
                        name: appLoginName,
                    },
                });
                if (appLoginResult.error) {
                    return { error: appLoginResult.error as FetchBaseQueryError };
                }
                const appLogin = appLoginCreation.parse(appLoginResult.data);

                // create credentials
                const result = await fetchBQ({
                    url: `/${appLogin.loginId}/credentials`,
                    method: 'POST',
                    responseHandler,
                });
                if (result.data) {
                    const appLoginCredentials = appLoginCredentialsSchema.parse(result.data);
                    return { data: { ...appLoginCredentials, loginId: appLogin.loginId } };
                } else {
                    return { error: result.error as FetchBaseQueryError };
                }
            },
            invalidatesTags: [TagType.APP_LOGINS],
        }),
    }),
});

export const {
    useGetAppLoginsQuery,
    useCreateAppLoginMutation,
    useDeleteAppLoginMutation,
    useCreateAppLoginCredentialsMutation,
    useCreateAppLoginWithCredentialsMutation,
} = appLoginApi;
