import packageJson from '../package.json';

const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    backend: {
        authenticationService: string | undefined;
        assetAdmin: string | undefined;
        appLoginService: string | undefined;
        appLoginExpirationDays: number | undefined;
    };
    external: {
        api: {
            tbm3: string;
            ocu3: string;
            manWebFleet: string;
        };
        iframe: {
            truckLoginSrc: string | undefined;
        };
    };
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    sentryToken: string | undefined;
    sentryModuleName: string;
    bookingRecommendationBaseUrl: string;
}

export const config: ConfigState = {
    backend: {
        authenticationService: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        assetAdmin: import.meta.env.VITE_ASSET_ADMIN_BASE_URL,
        appLoginService: import.meta.env.VITE_APP_LOGIN_SERVICE,
        appLoginExpirationDays: import.meta.env.VITE_APP_LOGIN_EXPIRATION_DAYS,
    },
    external: {
        api: {
            tbm3: import.meta.env.VITE_APP_TBM3_BACKEND,
            ocu3: import.meta.env.VITE_APP_OCU3_BACKEND,
            manWebFleet: import.meta.env.VITE_APP_MAN_WEBFLEET_BACKEND,
        },
        iframe: {
            truckLoginSrc: import.meta.env.VITE_APP_TRUCK_LOGIN_2STEP_IFRAME_SRC,
        },
    },
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: 'c70e4ed1-c076-4d51-b080-2761b0c65931',
        oauthScope: ['openid', 'profile', 'email', 'asset-administration.read', 'asset-administration.write'],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'assetConnectorWeb',
    bookingRecommendationBaseUrl: import.meta.env.VITE_BOOKING_RECOMMENDATION_BASE_URL,
};
