/* eslint-disable no-shadow */
import React from 'react';
import { z } from 'zod';
import { useSearchParams } from 'react-router-dom';

export type ObjectValues<T> = T[keyof T];

export const UserDataSubmitMessageType = 'USER_DATA_SUBMITTED';
export const IFrameCloseMessageMessageType = 'CLOSE_IFRAME';
export const ConnectorResizeMessageType = 'CONNECTOR_SPA_RESIZE';
export const ConnectorReadyMessageType = 'CONNECTOR_SPA_READY';
export const ConnectorErrorMessageType = 'CONNECTOR_DEVICE_REGISTRATION_ERROR';
export const ConnectorSuccessMessageType = 'CONNECTOR_DEVICE_REGISTRATION_SUCCESS';

export enum DeviceType {
    MAN_WEBFLEET = 'man-webfleet',
    OCU3 = 'ocu3',
    TRUCK_LOGIN_DEVICE = 'truck-login-device',
    POCKET_DRIVER_APP = 'pocket-driver-app',
    TBM3 = 'tbm3',
    NONE = 'none',
}

export const deviceTypesWithRequiredMileage = new Set([DeviceType.TBM3, DeviceType.OCU3, DeviceType.MAN_WEBFLEET]);
export const isDeviceTypeWithRequiredMileage = (deviceType: DeviceType) =>
    deviceTypesWithRequiredMileage.has(deviceType);

export enum AssetType {
    bus = 'bus',
    trailer = 'trailer',
    truck = 'truck',
    van = 'van',
}

export const AssetTypeSchema = z.nativeEnum(AssetType);
export const DeviceTypeEnum = z.nativeEnum(DeviceType);

export const assetTypeFromString = (raw: string) => {
    const parsed = AssetTypeSchema.safeParse(raw);
    if (parsed.success) {
        return parsed.data;
    } else {
        throw new Error(`could not extract asset type from string: '${raw}'`);
    }
};

export interface ConnectorResizeMessage {
    type: typeof ConnectorResizeMessageType;
    payload: {
        height: number;
    };
}

export interface ConnectorReadyMessage {
    type: typeof ConnectorReadyMessageType;
}

export interface ConnectorErrorMessage {
    type: typeof ConnectorErrorMessageType;
}

export interface ConnectorSuccessMessage {
    type: typeof ConnectorSuccessMessageType;
    payload: {
        deviceIds: string[];
        furtherInformationAvailable?: boolean;
    };
}

export interface UserSubmitDataMessage {
    type: typeof UserDataSubmitMessageType;
}

export interface IFrameCloseMessageMessage {
    type: typeof IFrameCloseMessageMessageType;
}

export type ToConnectorSpaMessage = UserSubmitDataMessage | IFrameCloseMessageMessage;

export type FromConnectorSpaMessage =
    | ConnectorSuccessMessage
    | ConnectorErrorMessage
    | ConnectorResizeMessage
    | ConnectorReadyMessage;

export enum IdentificationType {
    vin = 'vin',
}

export enum AssetStatus {
    active = 'active',
    archived = 'archived',
}

export interface AssetInput {
    id: string | null;
    account_id: string | null;
    name: string | null;
    identification: string | null;
    identification_type: IdentificationType | null;
    type: AssetType | null;
    status: AssetStatus;
    brand: string | null;
    license_plate: string | null;
    license_plate_country_code: string | null;
    tags: string[];
    mileage?: number;
}

export interface AssetRest {
    id: string;
    account_id: string;
    name: string;
    identification: string | null;
    identification_type: IdentificationType | null;
    type: AssetType;
    status: AssetStatus;
    brand: string | null;
    license_plate: string | null;
    license_plate_country_code: string | null;
    tags: string[];
}

export enum CreateDialogStepName {
    BASIC_ASSET_DATA = 'BASIC_ASSET_DATA',
    CONNECTOR_IFRAME = 'CONNECTOR_IFRAME',
    APP_LOGIN = 'APP_LOGIN',
    CLOSE = 'CLOSE',
}

export interface CreateDialogStep {
    body: React.ReactNode;
    showDialogBackButton: boolean;
    showNextButtonArrow: boolean;
    headline: React.ReactNode;
    nextButtonText: string | undefined;
    backButtonText: string;
    nextClickHandler?: () => void;
    backClickHandler?: () => void;
}
