import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetInput, AssetStatus, CreateDialogStepName, DeviceType } from '../pages/types';
import { RootState } from '../configuration/setup/store';

export interface AssetsState {
    createDialog: {
        currentStep: CreateDialogStepName;
        asset: AssetInput;
        chosenDeviceType: DeviceType | null;
        assetAlreadyExists: boolean;
    };
}

const getInitialAssetState = () => {
    return {
        id: null,
        account_id: null,
        brand: null,
        license_plate: null,
        license_plate_country_code: null,
        identification: null,
        identification_type: null,
        name: null,
        status: AssetStatus.active,
        tags: [],
        type: null,
    };
};

const initialState: AssetsState = {
    createDialog: {
        asset: getInitialAssetState(),
        chosenDeviceType: null,
        currentStep: CreateDialogStepName.BASIC_ASSET_DATA,
        assetAlreadyExists: false,
    },
};

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        goToStepAndFlushCurrentState: (
            state,
            action: PayloadAction<{
                stepName: CreateDialogStepName;
                asset: AssetInput;
                chosenDeviceType?: DeviceType;
            }>
        ) => {
            const chosenDeviceType = action.payload.chosenDeviceType ?? null;

            state.createDialog.currentStep = action.payload.stepName;
            state.createDialog.asset = action.payload.asset;
            state.createDialog.chosenDeviceType = chosenDeviceType;
        },

        setAssetToCreate: (state, action: PayloadAction<AssetInput>) => {
            state.createDialog.asset = action.payload;
            state.createDialog.assetAlreadyExists = true;
        },
    },
});

export const { goToStepAndFlushCurrentState, setAssetToCreate } = assetsSlice.actions;
export const assetsReducer = assetsSlice.reducer;

export const getBaseAsset = (state: RootState) => state.assets.createDialog.asset;
export const getCurrentStep = (state: RootState) => state.assets.createDialog.currentStep;

export const getAssetAlreadyExists = (state: RootState) => state.assets.createDialog.assetAlreadyExists;
