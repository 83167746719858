import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import { assetsApi } from '../../services/assetsApi';
import { connectorApi } from '../../services/connectorApi';
import { assetsReducer } from '../../reducers/assetsSlice';
import { appLoginApi } from '../../services/appLoginApi';

export const rootReducer = combineReducers({
    lang: langReducer,
    login: loginReducer,
    assets: assetsReducer,
    tokenHandling: tokenReducer,

    // Add the generated reducer as a specific top-level slice
    [appLoginApi.reducerPath]: appLoginApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [connectorApi.reducerPath]: connectorApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(appLoginApi.middleware, assetsApi.middleware, connectorApi.middleware),
        preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
