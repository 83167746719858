import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { trace } from '../configuration/setup/trace';
import { prepareHeaders } from './utils';
import { z } from 'zod';
import { config } from '../config';

// TBM3
// https://collaboration.msi.audi.com/stash/projects/VCPCON/repos/vcp-registry/browse/api/openapi_registrationoperations.yaml
// https://collaboration.msi.audi.com/stash/projects/VCPCON/repos/vcp-registry/browse/server/src/main/kotlin/cloud/rio/vcpregistry/rest/RegistryRestController.kt
// MAN WebFleet
// https://collaboration.msi.audi.com/stash/projects/MWEBFLEETC/repos/webfleet-registry/browse/server/src/main/kotlin/cloud/rio/webfleetregistry/rest/controller/RegistrationController.kt

// Ocu3
// https://collaboration.msi.audi.com/stash/projects/MANMBB/repos/mbb-registry/browse/src/main/java/cloud/rio/mbb/registry/controller/RegistrationController.java

export interface PostDeviceRegistrationRequest {
    vin: string;
    mileage: number;
}

// for TBM3 and MAN WebFleet
const deviceRegistrationResponse = z.object({
    deviceIds: z.array(z.string()),
});
type PostDeviceRegistrationResponse = z.infer<typeof deviceRegistrationResponse>;

// for Ocu3
const ocu3DeviceRegistrationResponse = z.object({ deviceId: z.string() });

export const connectorApi = createApi({
    reducerPath: 'rtkq/connectorApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders,
    }),
    endpoints: (build) => ({
        postTbm3DeviceRegistration: build.mutation<PostDeviceRegistrationResponse, PostDeviceRegistrationRequest>({
            query: (request) => {
                trace('postTbm3DeviceRegistration request', request);
                return { url: `${config.external.api.tbm3}/device-registrations`, body: request, method: 'POST' };
            },
            transformResponse: (response) => {
                trace('POST deviceRegistration response', response);
                return deviceRegistrationResponse.parse(response);
            },
        }),

        postManWebFleetDeviceRegistration: build.mutation<
            PostDeviceRegistrationResponse,
            PostDeviceRegistrationRequest
        >({
            query: (request: PostDeviceRegistrationRequest) => {
                trace('postManWebFleetDeviceRegistration request', request);
                return {
                    url: `${config.external.api.manWebFleet}/device-registrations`,
                    body: request,
                    method: 'POST',
                };
            },
            transformResponse: (response) => {
                trace('POST deviceRegistration response', response);
                return deviceRegistrationResponse.parse(response);
            },
        }),

        postOcu3DeviceRegistration: build.mutation<PostDeviceRegistrationResponse, PostDeviceRegistrationRequest>({
            query: (request: PostDeviceRegistrationRequest) => {
                trace('postOcu3DeviceRegistration request', request);
                return {
                    url: `${config.external.api.ocu3}/registration`,
                    body: request,
                    method: 'POST',
                };
            },
            transformResponse: (response) => {
                trace('POST deviceRegistration response', response);
                return { deviceIds: [ocu3DeviceRegistrationResponse.parse(response).deviceId] };
            },
        }),
    }),
});

export const {
    usePostTbm3DeviceRegistrationMutation,
    usePostManWebFleetDeviceRegistrationMutation,
    usePostOcu3DeviceRegistrationMutation,
} = connectorApi;
