import { AssetInput } from '../pages/types';

export const trimValue = (value: string | null | undefined): null | undefined | string => {
    if (value === undefined) {
        return undefined;
    }
    return value === null || value.trim().length === 0 ? null : value?.trim();
};

export const isAssetValid = (asset: AssetInput, isVinRequired: boolean, isMileageRequired: boolean) => {
    return (
        asset.type &&
        isAssetNameValid(asset.name) &&
        (isVinRequired ? isVinValid(asset.identification?.trim()) : isAssetIdentificationValid(asset.identification)) &&
        (isMileageRequired ? !!asset.mileage : true)
    );
};
export const isNullOrEmpty = (s: string | null | undefined) => s === undefined || s === null || s.trim().length === 0;

export const isAssetNameValid = (name: string | null) => {
    return !isNullOrEmpty(name);
};

export const isAssetIdentificationValid = (identification: string | null) => {
    return identification === null || identification.trim().length === 0 || isVinValid(identification.trim());
};

export const isVinValid = (identification: string | undefined) => {
    if (!identification) {
        return false;
    }
    const vinRegex = new RegExp('^[1234567890ABCDEFGHJKLMNPRSTUVWXYZ]{17}$');
    return vinRegex.test(identification);
};

export const getErrorCode = async (response: Response) => {
    let errorCode = null;

    if (response.status === 400) {
        const body: { title: string; status: number; detail: string } = await response.json();

        if (body.detail) {
            const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
            const matches = errorCodeRegex.exec(body.detail);
            errorCode = matches && matches.length === 2 ? matches[1] : null;
        }
    } else if ([401, 403].includes(response.status)) {
        errorCode = 'UNAUTHORIZED';
    }
    return errorCode;
};

export const XOR = (a: any, b: any) => (a || b) && !(a && b);
