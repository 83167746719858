// activate debug logging by adding cookie in browser:
// document.cookie="debug=true"
const debugCookieExists = () => document.cookie.indexOf('debug=true') >= 0;

export const trace =
    process.env.NODE_ENV !== 'production' || debugCookieExists()
        ? (...args: any) => {
              if (process.env.NODE_ENV !== 'test') {
                  console.log('[trace]', ...args);
              }
          }
        : () => {};
