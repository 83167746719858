// import * as Sentry from '@sentry/browser';
import { config } from '../../config';

const UNDEFINED_TOKEN = '<YOUR SENTRY DSN>';

if (import.meta.env.PROD) {
    // version and environment are defined in the webpack.define plugin
    // const release = config.serviceVersion;
    // const environment = config.serviceEnvironment;
    const dsn = config.sentryToken;

    const hasToken = !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken) {
        // Sentry.init({
        //     dsn,
        //     environment,
        //     release,
        // });
    }
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    // since we don't have a sentry project for this SPA, simply log the error
    console.warn(args);
    // Sentry.captureException(...args);
};
