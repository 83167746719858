import React, { ErrorInfo, ReactNode } from 'react';
// import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }, {}> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // since we don't have a sentry project for this SPA, simply log the error
        console.error(error, errorInfo);
        // Sentry.withScope((scope) => {
        //     scope.setExtra('componentStack', errorInfo.componentStack);
        //     Sentry.captureException(error);
        // });
    }

    render() {
        return this.props.children;
    }
}
